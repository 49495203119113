import { Brand } from './typings'

const mwh: Brand = {
  clientInfo: {
    displayName: "Men's Wearhouse",
    accountNumberLengths: [16],
    footerDisplay: 'menswearhouse.com',
    footerLink: 'https://www.menswearhouse.com/',
    applicationUrl: '',
    dsecApigeeClientId: {
      development: 'UjIGzIeRCPP4Wg0ML3qFaMlYKLWWWBM1',
      production: 'HpAGHdAVqpmXvwQ6SCHGqTAtI2gGtsYN'
    },
    persistApigeeClientId: {
      development: '',
      production: ''
    },
    setpayApigeeClientId: {
      development: '',
      production: ''
    },
    metaTagCardTitle: {
      en: 'Credit Card',
      es: 'Tarjeta de Crédito'
    },
    metaTagCardDescription: {
      en: `Log into your Men's Wearhouse credit card account online to pay your bills, check your FICO score, sign up for paperless billing, and manage your account preferences`,
      es: `Ingrese a su cuenta de la Tarjeta de Crédito Men's Wearhouse en línea para pagar sus facturas, revisar su puntuación FICO, inscribirse en facturas digitales y manejar su cuenta.`
    },
    languages: ['en'],
    vanityUrl: 'menswearhouse.com',
    hostname: 'mwh.syf.com',
    linkingRedirect: '',
    ivaKey: '',
    hasMss: true,
    hasCpl: false,
    promoUrl: '',
    hasCplOldErrorCode: false,
    isMultiAccount: false,
    footerContent: {
      en: "Your Perfect Fit® Credit Card is issued by Synchrony Bank. The Synchrony Bank Privacy Policy governs the use of the Perfect Fit® Credit Card. The use of this site is governed by the use of the Synchrony Bank Internet Privacy Policy, which is different from the privacy policy of Men's Wearhouse®.",
      es: ''
    },
    phoneNumbers: {
      loan: '',
      plcc: '1-800-400-3761',
      dualcard: '',
      fraud: '1-800-400-3761',
      customerService: '1-800-400-3761'
    },
    iOSAppId: '',
    androidApp: {
      id: '',
      author: '',
      title: '',
      image: ''
    },
    crossBorder: false,
    cplHeroBannerDisplayName: '',
    termsOfService: {
      en: '/essimages/cs/groups/ess_webasset/plcc/@menswear/en/html/ess010493.html',
      es: ''
    },
    onlinePrivacy: {
      en: '/essimages/cs/groups/ess_webasset/plcc/@menswear/en/html/ess010258.html',
      es: ''
    },
    privacyPolicy: {
      en: '/essimages/cs/groups/ess_webasset/plcc/@menswear/en/html/ess010482.html',
      es: ''
    },
    websiteUsage: {
      en: '/essimages/cs/groups/ess_webasset/plcc/@menswear/en/html/ess010263.html',
      es: ''
    },
    voice: {
      cardName: '',
      google: {
        termsAndConditionsLink: ''
      },
      alexa: {
        termsAndConditionsLink: ''
      }
    }
  },
  palette: {
    button: {
      primary: {
        text: '#FFFFFF',
        hover: '#0b2f52'
      },
      secondary: {
        hover: '#071d5633'
      },
      background: '#071D56'
    },
    field: {
      icon: '#000000',
      focus: '#000000',
      label: ''
    },
    progressStepBar: {
      fill: '#071D56',
      label: '#071D56'
    },
    progressBar: {
      complete: '',
      error: '#c63527',
      inProgress: '#0072ad',
      background: '#e9eaeb'
    },
    checkbox: {
      background: '#333333',
      font: '#FFFFFF'
    },
    switch: { background: '' },
    heroBackground: '#F8F8F8',
    bodyBackground: '#f2f2f2',
    iconBackground: '#FFFFFF',
    headerBackground: '#FFFFFF',
    modalBackground: '#FFFFFF',
    formBackground: '#f2f2f2',
    headerDivider: '#071D56',
    footerDivider: '#6A6A6A',
    contrastingText: '#FFFFFF',
    bodyText: '#333333',
    languageSelector: '#ed0a18',
    focus: '',
    link: '#4c68ae',
    footer: {
      background: '#FFFFFF',
      text: '#6A6A6A',
      link: '#6A6A6A'
    }
  },
  images: {
    logo: {
      en: '/assets/synchrony/logo.svg',
      es: '/assets/synchrony/logo.svg'
    },
    hero: '',
    heroBgDesktop: '',
    heroBgMobile: '',
    mainPromo: {
      en: '/assets/mwh/promo.png',
      es: '/assets/mwh/promo.png'
    },
    voiceCplLogo: ''
  }
}

export default mwh
